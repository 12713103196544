<mat-card [formGroup]="form">
  <mat-card-content formGroupName="userInformation">
    <div class="tw-grid">
      <p class="tw-text-base tw-font-bold tw-mb-4">Your Information</p>
      <hr class="tw-mb-4" />

      <mat-form-field [hidden]="!config.userFirstName.visible" class="tw-mb-1">
        <mat-label>First Name</mat-label>
        <input
          matInput
          formControlName="userFirstName"
          type="text"
          inputmode="text"
          placeholder="First Name"
          autocomplete="off"
          [required]="config.userFirstName.required"
        />
        <mat-error *ngIf="userInformation?.get('userFirstName')?.errors">
          <nsc-validation-error class="tw-block" [errors]="userInformation?.get('userFirstName')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.userLastName.visible" class="tw-mb-1">
        <mat-label>Last Name</mat-label>
        <input
          matInput
          formControlName="userLastName"
          type="text"
          inputmode="text"
          placeholder="Last Name"
          autocomplete="off"
          [required]="config.userLastName.required"
        />
        <mat-error *ngIf="userInformation?.get('userLastName')?.errors">
          <nsc-validation-error class="tw-block" [errors]="userInformation?.get('userLastName')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.userPhone.visible" class="tw-mb-1">
        <mat-label>Phone Number <small>(Preferred: 123-456-7890)</small></mat-label>
        <input
          matInput
          formControlName="userPhone"
          type="tel"
          inputmode="tel"
          placeholder="Phone Number"
          autocomplete="off"
          [required]="config.userPhone.required"
        />
        <mat-error *ngIf="userInformation?.get('userPhone')?.errors">
          <nsc-validation-error class="tw-block" [errors]="userInformation?.get('userPhone')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.userEmail.visible" class="tw-mb-1">
        <mat-label>Email</mat-label>
        <input
          matInput
          formControlName="userEmail"
          type="email"
          inputmode="email"
          placeholder="Email"
          autocomplete="off"
          [required]="config.userEmail.required"
        />
        <mat-error *ngIf="userInformation?.get('userEmail')?.errors">
          <nsc-validation-error class="tw-block" [errors]="userInformation?.get('userEmail')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.userCompany.visible" class="tw-mb-1">
        <mat-label>Company</mat-label>
        <input
          matInput
          formControlName="userCompany"
          type="text"
          inputmode="text"
          placeholder="Company"
          autocomplete="off"
          [required]="config.userCompany.required"
        />
        <mat-error *ngIf="userInformation?.get('userCompany')?.errors">
          <nsc-validation-error class="tw-block" [errors]="userInformation?.get('userCompany')?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>
